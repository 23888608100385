/*
 * Dashboard Messages
 *
 * This contains all the text for the Dashboard container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.PortalLayout';

export default defineMessages({
  menu: {
    id: `${scope}.menu`,
    defaultMessage: 'Menu',
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard',
  },
  mentalHealthAssessment: {
    id: `${scope}.mentalHealthAssessment`,
    defaultMessage: 'Mental health assessment',
  },
  virtualCounselling: {
    id: `${scope}.virtualCounselling`,
    defaultMessage: 'Virtual counselling',
  },
  employeeMemberResources: {
    id: `${scope}.employeeMemberResources`,
    defaultMessage: 'Employee/member resources',
  },
  employeeResources: {
    id: `${scope}.employeeResources`,
    defaultMessage: 'Employee resources',
  },
  peopleLeaderResources: {
    id: `${scope}.peopleLeaderResources`,
    defaultMessage: 'People leader resources',
  },
  planSponsorResources: {
    id: `${scope}.planSponsorResources`,
    defaultMessage: 'Plan sponsor resources',
  },
  trainingAndConsulting: {
    id: `${scope}.trainingAndConsulting`,
    defaultMessage: 'Training & consulting',
  },
  settingsAndHistory: {
    id: `${scope}.settingsAndHistory`,
    defaultMessage: 'Settings & history',
  },
  signOut: {
    id: `${scope}.signOut`,
    defaultMessage: 'Sign out',
  },
  supportAndFaq: {
    id: `${scope}.supportAndFaq`,
    defaultMessage: 'Support and faq',
  },
  metrics: {
    id: `${scope}.metrics`,
    defaultMessage: 'Metrics',
  },
  eapNavHeader: {
    id: `${scope}.eapNavHeader`,
    defaultMessage: 'EAP: Work, health and life services',
  },
  criticalIncidentServices: {
    id: `${scope}.criticalIncidentServices`,
    defaultMessage: 'Critical incident support services',
  },
  specializedServices: {
    id: `${scope}.specializedServices`,
    defaultMessage: 'Specialized services',
  },
  managementConsulting: {
    id: `${scope}.managementConsulting`,
    defaultMessage: 'Management consultation services',
  },
  managementConsultingBbd: {
    id: `${scope}.managementConsultingBbd`,
    defaultMessage: 'HR and management consultations',
  },
  icbt: {
    id: `${scope}.icbt`,
    defaultMessage: 'iCBT Self-Help Learning',
  },
  carepath: {
    id: `${scope}.carepath`,
    defaultMessage: 'Carepath',
  },
  businessAssistance: {
    id: `${scope}.businessAssistance`,
    defaultMessage: 'Business assistance program',
  },
  mentalHealthSupport: {
    id: `${scope}.mentalHealthSupport`,
    defaultMessage: 'Mental health support',
  },
  getSupport: {
    id: `${scope}.getSupport`,
    defaultMessage: 'Get support',
  },
  healthNavigation: {
    id: `${scope}.healthNavigation`,
    defaultMessage: 'Health navigation',
  },
  cancerAssistance: {
    id: `${scope}.cancerAssistance`,
    defaultMessage: 'Cancer assistance',
  },
  secondOpinionServices: {
    id: `${scope}.secondOpinionServices`,
    defaultMessage: 'Second opinion services',
  },
  financialLegalServices: {
    id: `${scope}.financialLegalServices`,
    defaultMessage: 'Financial and legal advisory services',
  },
});
