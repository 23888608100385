/**
 *
 * PortalLayout
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';

import PanelWrapper from 'components/PanelWrapper';
import Panel from 'components/Panel';
import PanelItem from 'components/PanelItem';
import TopBar from 'components/TopBar';
import Options from 'components/Options';
import Navigation from 'components/Navigation';
import NavigationItem from 'components/NavigationItem';
import NavigationHeader from 'components/NavigationHeader';
import H1 from 'components/H1';
import Logo from 'images/PC-Nav.svg';
import LogoFrench from 'images/PC-PeopleCare-Logos_FR.svg';
import PrincessAuto from 'images/princess-auto.png';
import { phoneSize } from 'global-styles';
import styled from 'styled-components';
import { makeSelectApp } from 'containers/App/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { allowedToViewMetrics } from 'utils/auth';
import { isEap } from 'containers/Dashboard/helpers';
import { BBDBAP, BBDEAP } from 'utils/environment';
import BBDBAPLogo from 'images/pcCarePathBap.png';
import BBDBAPLogofr from 'images/pcCarePathBapFr.png';
import EapLogoFr from '../../images/pc-eap-fr-logo.png';
import EapLogo from '../../images/pc-eap-eng-logo.png';
import Wrapper from './Wrapper';
import messages from './messages';

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 650px;
  a {
    width: 300px;
  }
  @media (max-width: ${phoneSize}) {
    width: 200px;
  }
`;

const FrenchLogoWrapper = styled.div`
  width: 300px;
  img {
    width: 100%;
  }
  @media (max-width: ${phoneSize}) {
    img {
      width: 70%;
    }
  }
`;

const EapLogoWrapper = styled.div`
  width: 200px;
  img {
    width: 100%;
  }
  @media (max-width: ${phoneSize}) {
    img {
      width: 70%;
    }
  }
`;

const BapLogoWrapper = styled.div`
  width: 150px;
  img {
    width: 100%;
  }
  @media (max-width: ${phoneSize}) {
    img {
      width: 70%;
    }
  }
`;

const PrincessLogoWrapper = styled.div`
  width: 200px;
  img {
    width: 100%;
  }
  @media (max-width: ${phoneSize}) {
    img {
      width: 70%;
    }
  }
`;

class PortalLayout extends React.PureComponent {
  render() {
    const {
      activeTab,
      title,
      children,
      customChildren,
      user,
      locale,
      customDashText,
    } = this.props;
    const status = user && user.data && user.data.people_care;
    const company = user && user.data && user.data.company;

    const renderLogo = () => {
      if (BBDBAP) {
        if (locale === 'fr') {
          return (
            <BapLogoWrapper>
              <img src={BBDBAPLogofr} alt="french bbd bap logo" />
            </BapLogoWrapper>
          );
        }
        return (
          <BapLogoWrapper>
            <img src={BBDBAPLogo} alt="eap logo" />
          </BapLogoWrapper>
        );
      }
      if (BBDEAP) {
        if (locale === 'fr') {
          return (
            <EapLogoWrapper>
              <img src={EapLogoFr} alt="fr pc eap logo" />
            </EapLogoWrapper>
          );
        }
        return (
          <EapLogoWrapper>
            <img src={EapLogo} alt="pc eap logo" />
          </EapLogoWrapper>
        );
      }
      if (isEap(company)) {
        if (locale === 'fr') {
          return (
            <EapLogoWrapper>
              <img src={EapLogoFr} alt="fr pc eap logo" />
            </EapLogoWrapper>
          );
        }
        return (
          <EapLogoWrapper>
            <img src={EapLogo} alt="pc eap logo" />
          </EapLogoWrapper>
        );
      }
      return locale === 'fr' ? (
        <FrenchLogoWrapper>
          <img src={LogoFrench} alt="french logo" />
        </FrenchLogoWrapper>
      ) : (
        <SVG src={Logo} alt="logo" />
      );
    };

    const renderNavigation = planType => {
      if (BBDBAP)
        return <BbdBapNavigation status={status} activeTab={activeTab} />;
      if (BBDEAP)
        return <BbdEapNavigation status={status} activeTab={activeTab} />;
      if (planType === 'eap')
        return <EapNavigation status={status} activeTab={activeTab} />;
      return <StandardNavigation status={status} activeTab={activeTab} />;
    };

    return (
      <Wrapper>
        <TopBar>
          <LinkWrapper>
            <Link to="/dashboard">{renderLogo()}</Link>
            {company.name === 'Princess Auto Ltd.' && (
              <a href="https://princessauto.com" target="_blank">
                <PrincessLogoWrapper>
                  <img
                    src={PrincessAuto}
                    alt="princess auto"
                    style={{ width: '50%' }}
                  />
                </PrincessLogoWrapper>
              </a>
            )}
          </LinkWrapper>
          <Options />
        </TopBar>
        <PanelWrapper>
          <Navigation>
            <NavigationHeader>
              <FormattedMessage {...messages.menu} />
            </NavigationHeader>
            {renderNavigation(company.plan_type)}
            <Link to="/settings">
              <NavigationItem
                className={activeTab === 'settings' ? 'active' : null}
              >
                <FormattedMessage {...messages.settingsAndHistory} />
              </NavigationItem>
            </Link>
            <Link to="/faq">
              <NavigationItem className={activeTab === 'faq' ? 'active' : null}>
                <FormattedMessage {...messages.supportAndFaq} />
              </NavigationItem>
            </Link>
            {allowedToViewMetrics() && (
              <Link to="/metrics">
                <NavigationItem
                  className={activeTab === 'metrics' ? 'active' : null}
                >
                  <FormattedMessage {...messages.metrics} />
                </NavigationItem>
              </Link>
            )}
          </Navigation>
          {customChildren ? (
            <Panel style={{ padding: '0px' }}>{children}</Panel>
          ) : (
            <Panel>
              {title && <H1>{title}</H1>}
              <PanelItem>{children}</PanelItem>
              {customDashText}
            </Panel>
          )}
        </PanelWrapper>
      </Wrapper>
    );
  }
}

const StandardNavigation = ({ activeTab, status }) => (
  <>
    <Link to="/dashboard">
      <NavigationItem className={activeTab === 'dashboard' ? 'active' : null}>
        <FormattedMessage {...messages.dashboard} />
      </NavigationItem>
    </Link>
    <Link to="/assessment">
      <NavigationItem className={activeTab === 'assessment' ? 'active' : null}>
        <FormattedMessage {...messages.mentalHealthAssessment} />
      </NavigationItem>
    </Link>
    <Link to="/virtual_counselling">
      <NavigationItem className={activeTab === 'counselling' ? 'active' : null}>
        <FormattedMessage {...messages.virtualCounselling} />
      </NavigationItem>
    </Link>
    <Link to="/employee_resources">
      <NavigationItem
        className={activeTab === 'employee_resources' ? 'active' : null}
      >
        <FormattedMessage {...messages.employeeMemberResources} />
      </NavigationItem>
    </Link>
    {status === 'People Leader' && (
      <>
        <Link to="/manager_resources">
          <NavigationItem
            className={activeTab === 'manager_resources' ? 'active' : null}
          >
            <FormattedMessage {...messages.peopleLeaderResources} />
          </NavigationItem>
        </Link>
        <Link to="/plan_sponsors">
          <NavigationItem
            className={activeTab === 'plan_sponsors' ? 'active' : null}
          >
            <FormattedMessage {...messages.planSponsorResources} />
          </NavigationItem>
        </Link>
        <Link to="/training">
          <NavigationItem
            className={activeTab === 'training' ? 'active' : null}
          >
            <FormattedMessage {...messages.trainingAndConsulting} />
          </NavigationItem>
        </Link>
      </>
    )}
  </>
);

const EapNavigation = ({ activeTab, status }) => (
  <>
    <Link to="/dashboard">
      <NavigationItem className={activeTab === 'dashboard' ? 'active' : null}>
        <FormattedMessage {...messages.dashboard} />
      </NavigationItem>
    </Link>
    <Link to="/virtual_counselling">
      <NavigationItem className={activeTab === 'counselling' ? 'active' : null}>
        <FormattedMessage {...messages.eapNavHeader} />
      </NavigationItem>
    </Link>
    <Link to="/assessment">
      <NavigationItem className={activeTab === 'assessment' ? 'active' : null}>
        <FormattedMessage {...messages.mentalHealthAssessment} />
      </NavigationItem>
    </Link>
    <a href="https://www.engagementrx.com/fv2/pceap/boubYz">
      <NavigationItem className={activeTab === 'icbt' ? 'active' : null}>
        <FormattedMessage {...messages.icbt} />
      </NavigationItem>
    </a>
    {status === 'People Leader' && (
      <Link to="/specialized_services">
        <NavigationItem
          className={activeTab === 'specialized_services' ? 'active' : null}
        >
          <FormattedMessage {...messages.specializedServices} />
        </NavigationItem>
      </Link>
    )}
    {status === 'People Leader' && (
      <>
        <Link to="/critical_incident_services">
          <NavigationItem
            className={
              activeTab === 'critical_incident_services' ? 'active' : null
            }
          >
            <FormattedMessage {...messages.criticalIncidentServices} />
          </NavigationItem>
        </Link>
        <Link to="/management_consulting">
          <NavigationItem
            className={activeTab === 'management_consulting' ? 'active' : null}
          >
            <FormattedMessage {...messages.managementConsulting} />
          </NavigationItem>
        </Link>
      </>
    )}
    <Link to="/employee_resources">
      <NavigationItem
        className={activeTab === 'employee_resources' ? 'active' : null}
      >
        <FormattedMessage {...messages.employeeMemberResources} />
      </NavigationItem>
    </Link>
    {status === 'People Leader' && (
      <>
        <Link to="/manager_resources">
          <NavigationItem
            className={activeTab === 'manager_resources' ? 'active' : null}
          >
            <FormattedMessage {...messages.peopleLeaderResources} />
          </NavigationItem>
        </Link>
        <Link to="/plan_sponsors">
          <NavigationItem
            className={activeTab === 'plan_sponsors' ? 'active' : null}
          >
            <FormattedMessage {...messages.planSponsorResources} />
          </NavigationItem>
        </Link>
        <Link to="/training">
          <NavigationItem
            className={activeTab === 'training' ? 'active' : null}
          >
            <FormattedMessage {...messages.trainingAndConsulting} />
          </NavigationItem>
        </Link>
      </>
    )}
  </>
);

const BbdEapNavigation = ({ activeTab, status }) => (
  <>
    <Link to="/virtual_counselling">
      <NavigationItem
        className={activeTab === 'virtual_counselling' ? 'active' : null}
      >
        <FormattedMessage {...messages.eapNavHeader} />
      </NavigationItem>
    </Link>
    <Link to="/assessment">
      <NavigationItem className={activeTab === 'assessment' ? 'active' : null}>
        <FormattedMessage {...messages.mentalHealthAssessment} />
      </NavigationItem>
    </Link>
    <a href="https://engagementrx.com/pceap/app2/#!/landing">
      <NavigationItem className={activeTab === 'icbt' ? 'active' : null}>
        <FormattedMessage {...messages.icbt} />
      </NavigationItem>
    </a>
    <Link to="/employee_resources">
      <NavigationItem
        className={activeTab === 'employee_resources' ? 'active' : null}
      >
        <FormattedMessage {...messages.employeeResources} />
      </NavigationItem>
    </Link>
    <Link to="/carepath">
      <NavigationItem className={activeTab === 'carepath' ? 'active' : null}>
        <FormattedMessage {...messages.carepath} />
      </NavigationItem>
    </Link>
    {status === 'People Leader' && (
      <>
        <Link to="/business_assistance">
          <NavigationItem
            className={activeTab === 'business_assistance' ? 'active' : null}
          >
            <FormattedMessage {...messages.businessAssistance} />
          </NavigationItem>
        </Link>
        <Link to="/critical_incident_services">
          <NavigationItem
            className={
              activeTab === 'critical_incident_services' ? 'active' : null
            }
          >
            <FormattedMessage {...messages.criticalIncidentServices} />
          </NavigationItem>
        </Link>
        <Link to="/specialized_services">
          <NavigationItem
            className={activeTab === 'specialized_services' ? 'active' : null}
          >
            <FormattedMessage {...messages.specializedServices} />
          </NavigationItem>
        </Link>
        <Link to="/management_consulting">
          <NavigationItem
            className={activeTab === 'management_consulting' ? 'active' : null}
          >
            <FormattedMessage {...messages.managementConsultingBbd} />
          </NavigationItem>
        </Link>
      </>
    )}
    {status === 'People Leader' && (
      <>
        <Link to="/training">
          <NavigationItem
            className={activeTab === 'training' ? 'active' : null}
          >
            <FormattedMessage {...messages.trainingAndConsulting} />
          </NavigationItem>
        </Link>
        <Link to="/manager_resources">
          <NavigationItem
            className={activeTab === 'manager_resources' ? 'active' : null}
          >
            <FormattedMessage {...messages.peopleLeaderResources} />
          </NavigationItem>
        </Link>
        <Link to="/plan_sponsors">
          <NavigationItem
            className={activeTab === 'plan_sponsors' ? 'active' : null}
          >
            <FormattedMessage {...messages.planSponsorResources} />
          </NavigationItem>
        </Link>
      </>
    )}
  </>
);

const BbdBapNavigation = ({ activeTab, status }) => (
  <>
    <Link to="/virtual_counselling">
      <NavigationItem
        className={activeTab === 'virtual_counselling' ? 'active' : null}
      >
        {status === "People Leader" ? (
          <FormattedMessage {...messages.getSupport} />
        ) : (
          <FormattedMessage {...messages.mentalHealthSupport} />
        )}
      </NavigationItem>
    </Link>
    <Link to="/assessment">
      <NavigationItem className={activeTab === 'assessment' ? 'active' : null}>
        <FormattedMessage {...messages.mentalHealthAssessment} />
      </NavigationItem>
    </Link>
    <Link to="/health_navigation">
      <NavigationItem
        className={activeTab === 'health_navigation' ? 'active' : null}
      >
        <FormattedMessage {...messages.healthNavigation} />
      </NavigationItem>
    </Link>
    <Link to="/cancer_assistance">
      <NavigationItem
        className={activeTab === 'cancer_assistance' ? 'active' : null}
      >
        <FormattedMessage {...messages.cancerAssistance} />
      </NavigationItem>
    </Link>
    <Link to="/second_opinion">
      <NavigationItem
        className={activeTab === 'second_opinion' ? 'active' : null}
      >
        <FormattedMessage {...messages.secondOpinionServices} />
      </NavigationItem>
    </Link>
    {status === 'People Leader' && (
      <>
        <Link to="/management_consulting">
          <NavigationItem
            className={activeTab === 'management_consulting' ? 'active' : null}
          >
            <FormattedMessage {...messages.managementConsultingBbd} />
          </NavigationItem>
        </Link>
        <Link to="/financial_legal_services">
          <NavigationItem
            className={
              activeTab === 'financial_legal_services' ? 'active' : null
            }
          >
            <FormattedMessage {...messages.financialLegalServices} />
          </NavigationItem>
        </Link>
        <Link to="/critical_incident_services">
          <NavigationItem
            className={
              activeTab === 'critical_incident_services' ? 'active' : null
            }
          >
            <FormattedMessage {...messages.criticalIncidentServices} />
          </NavigationItem>
        </Link>
      </>
    )}
    <Link to="/employee_resources">
      <NavigationItem
        className={activeTab === 'employee_resources' ? 'active' : null}
      >
        <FormattedMessage {...messages.employeeMemberResources} />
      </NavigationItem>
    </Link>
    {status === 'People Leader' && (
      <>
        <Link to="/manager_resources">
          <NavigationItem
            className={activeTab === 'manager_resources' ? 'active' : null}
          >
            <FormattedMessage {...messages.peopleLeaderResources} />
          </NavigationItem>
        </Link>
        <Link to="/plan_sponsors">
          <NavigationItem
            className={activeTab === 'plan_sponsors' ? 'active' : null}
          >
            <FormattedMessage {...messages.planSponsorResources} />
          </NavigationItem>
        </Link>
      </>
    )}
  </>
);

PortalLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  activeTab: PropTypes.string,
  customChildren: PropTypes.bool,
  user: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  customDashText: PropTypes.node,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectApp(),
  locale: makeSelectLocale(),
});

export default connect(mapStateToProps)(PortalLayout);
