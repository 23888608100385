/*
 * BusinessAssistance Messages
 *
 * This contains all the text for the Carepath container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.BusinessAssistance';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Business assistance program (BAP)',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage:
      '*Please note that you must be a business owner or supervisor with employees/direct reports to access this service.*',
  },
  body1: {
    id: `${scope}.body1`,
    defaultMessage: '<b><u>HR and management consultation</u></b>',
  },
  body2: {
    id: `${scope}.body2`,
    defaultMessage:
      'As part of your Business Assistance Program, you receive complimentary HR and management consultation regarding challenging employee issues, including performance management, absenteeism, conflict, difficult behaviour, and return-to-work strategies. These services are available to assist business owners and people leaders on how to appropriately discuss options with employees, as well as coach on how to approach sensitive employee matters in conjunction with existing HR policies and regulations.',
  },
  body3: {
    id: `${scope}.body3`,
    defaultMessage:
      'Manager and HR consultations are unlimited and can be accessed as often as required.',
  },
  body4: {
    id: `${scope}.body4`,
    defaultMessage:
      '<b>To request a virtual management consultation contact <a href="mailto:consultation@inkblottherapy.com">consultation@inkblottherapy.com</a>. <br/><br/>For immediate support call: 1-855-933-0103.</b>',
  },
  body5: {
    id: `${scope}.body5`,
    defaultMessage: '<b><u>Financial and legal advisory services</u></b>',
  },
  body6: {
    id: `${scope}.body6`,
    defaultMessage:
      'People Connect Business Assistance Program (BAP) offers secure and confidential telephonic consultations with a professional matched according to your needs.',
  },
  body7: {
    id: `${scope}.body7`,
    defaultMessage:
      'Through People Connect BAP, business owners will have access to:',
  },
  body8: {
    id: `${scope}.body8`,
    defaultMessage: '<b>Legal advisory services</b>',
  },
  body9: {
    id: `${scope}.body9`,
    defaultMessage:
      'Consultations with highly qualified attorneys are typically 30 minutes in length and include common corporate law, labour/employment law, real estate and family law questions.',
  },
  body10: {
    id: `${scope}.body10`,
    defaultMessage: '<b>Financial advisory services</b>',
  },
  body11: {
    id: `${scope}.body11`,
    defaultMessage:
      'Obtain answers and recommendations to solve business challenges, make informed decisions, understand tax implications, and better manage company finances. ',
  },
  body12: {
    id: `${scope}.body12`,
    defaultMessage:
      'Advisory services are unlimited and can be accessed as often as required.',
  },
  body13: {
    id: `${scope}.body13`,
    defaultMessage:
      'Consultations can be arranged Monday – Friday during the following hours:',
  },
  body14: {
    id: `${scope}.body14`,
    defaultMessage: 'Financial: 8am - 9pm EST',
  },
  body15: {
    id: `${scope}.body15`,
    defaultMessage: 'Legal: 9am - 8pm EST',
  },
  body16: {
    id: `${scope}.body16`,
    defaultMessage:
      'To arrange a virtual consultation, select <b>Additional Services</b> at the top of the dashboard and choose the service you would like to access, then click next. Select the category reason for your request and then click next.',
  },
  body17: {
    id: `${scope}.body17`,
    defaultMessage:
      '<b>Financial:</b> Enter your location and contact information and select two timeframes that you could be available for a phone call. Then select submit. A financial services representative will contact you directly to schedule your appointment.',
  },
  body18: {
    id: `${scope}.body18`,
    defaultMessage:
      '<b>Legal:</b> Call the hotline number that appears and use the consultation number provided to initiate your request.',
  },
  body19: {
    id: `${scope}.body19`,
    defaultMessage: '<b><u>Critical incident support services</u></b>',
  },
  body20: {
    id: `${scope}.body20`,
    defaultMessage:
      'Supporting employee health in times of workplace disruption and critical incidents (i.e. employee death, workplace accident or violence, fire, robbery, bankruptcy, etc).',
  },
  body21: {
    id: `${scope}.body21`,
    defaultMessage:
      '<i>Fee for service (initial consultation included)</i> <br/><br/><a href="mailto:info@R3c.com">Email: info@R3c.com</a>',
  },
  body22: {
    id: `${scope}.body22`,
    defaultMessage: 'Telephone: 1-855-588-7590 (24/7 live call answer)',
  },
});
