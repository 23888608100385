/**
 *
 * VirtualCounselling
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { createStructuredSelector } from 'reselect';

import { BBDBAP, BBDEAP, ENVIRONMENT, THERAPY_URL } from 'utils/environment';
import PortalLayout from 'components/PortalLayout';
import P from 'components/P';
import Laptop from 'images/virtaul-counselling/laptop.png';
import Logo from 'images/virtaul-counselling/logo.png';
import { InkblotTheme, Button } from 'inkblot-ui';
import { logEvent } from 'utils/amplitude';
import { isEap, isPeopleLeader } from 'containers/Dashboard/helpers';
import bbdLogoFr from 'images/pcCarePathBapFr.png';
import bbdLogo from 'images/pcCarePathBap.png';
import { fetchUser } from '../App/actions';
import { trackUserClick } from '../EmployeeResources/actions';
import Card from './Card';
import messages from './messages';
import PcEapLogo from '../../images/pc-eap-eng-logo.png';
import PcEapLogoFr from '../../images/pc-eap-fr-logo.png';

import reducer from '../EmployeeResources/reducer';
import saga from '../EmployeeResources/saga';
import { makeSelectApp } from 'containers/App/selectors';

const StyledP = styled(P)`
  font-size: 18px;
  color: #444e69;

  ul {
    text-align: left;
    margin-top: 12px;
  }
`;

const ContainerP = styled.div`
  width: 90%;
  margin: 0 auto;
`;

class VirtualCounselling extends Component {
  constructor() {
    super();
    this.state = { coughlin: false };
  }

  componentDidMount() {
    this.props.fetchUser(user => {
      this.setState({
        coughlin: user.company_info.coughlin_yn,
      });
    });
  }

  handleUserClick = () => {
    logEvent('go to inkblot', {
      location: window.location.pathname,
      domain: window.location.hostname,
    });
    this.props.trackUserClick({
      article_name: 'Virtual counselling from /virtual_counselling',
    });
  };

  render() {
    const { intl, user } = this.props;
    const { coughlin } = this.state;

    const status = user.data.people_care;
    const { company } = user.data;

    const renderTitle = () => {
      if (BBDBAP && isPeopleLeader(status)) {
        return intl.formatMessage(messages.getSupport);
      }
      if (BBDBAP) {
        return intl.formatMessage(messages.mentalHealthSupport);
      }
      if (company && company.plan_type === 'eap') {
        return intl.formatMessage(messages.workHealthAndLifeServices);
      }
      return intl.formatMessage(messages.virtualTherapy);
    };

    const renderBody = () => {
      if (BBDBAP && isPeopleLeader(status)) return <BbdBapLeaderBody/>
      else if (BBDBAP) return <BbdBapBody/>;
      else if (company && company.plan_type === 'eap' || BBDEAP) return <EapBody/>;
      else {
        return <DefaultBody />;
      }
    };

    const BbdBapBody = () => (
      <ContainerP>
        <StyledP>
          <FormattedMessage {...messages.bapBody} />
        </StyledP>
        <StyledP>
          <FormattedMessage {...messages.bapBody2} />
        </StyledP>
        <StyledP>
          <FormattedHTMLMessage tagName="p" {...messages.bapBody3} />
        </StyledP>
        <StyledP>
          <FormattedMessage {...messages.bapBody4} />
        </StyledP>
        <StyledP>
          <i>
            <FormattedMessage {...messages.bapBody5} />
          </i>
        </StyledP>
      </ContainerP>
    );

    const BbdBapLeaderBody = () => (
      <ContainerP>
          <FormattedHTMLMessage tagName={StyledP} {...messages.bapPeopleLeaderBody}/>
      </ContainerP>
    );

    const EapBody = () => (
      <ContainerP>
        <StyledP>
          <b>
            <FormattedMessage {...messages.eapBody} />
          </b>
        </StyledP>
        <StyledP>
          <b>
            <FormattedMessage {...messages.eapBody2} />
          </b>
        </StyledP>
        <StyledP>
          <FormattedHTMLMessage tagName="div" {...messages.eapBody3} />
        </StyledP>
        <StyledP>
          <FormattedMessage {...messages.eapBody4} />
        </StyledP>
      </ContainerP>
    );

    const DefaultBody = () => (
        <ContainerP>
          <StyledP>
            <FormattedMessage {...messages.inkblotOffers} />
          </StyledP>
          {!coughlin && (
            <>
              <StyledP>
                <FormattedHTMLMessage {...messages.yourInitial} />
              </StyledP>
              <StyledP>
                <FormattedHTMLMessage
                  {...messages.fromThere}
                  values={{
                    pricePer30Min:
                      ENVIRONMENT === 'US_PRODUCTION' ? '55' : '45',
                    pricePerHour:
                      ENVIRONMENT === 'US_PRODUCTION' ? '110' : '90',
                  }}
                />
              </StyledP>
            </>
          )}
          <StyledP>
            <FormattedMessage {...messages.sessionsCanBe} />
            {coughlin && (
              <b>
                <FormattedMessage {...messages.noCreditCard} />
              </b>
            )}
          </StyledP>
          <StyledP>
            <FormattedMessage {...messages.youMayChoose} />
          </StyledP>
          <StyledP>
            <FormattedMessage {...messages.startFeeling} />
          </StyledP>
        </ContainerP>
    );

    return (
      <div>
        <Helmet>
          <title>{renderTitle()}</title>
          <meta
            name="description"
            content="Description of Virtual Counselling"
          />
        </Helmet>
        <PortalLayout title={renderTitle()} activeTab="counselling">
          <Card>
            {!BBDBAP && <img className="laptop" src={Laptop} alt="laptop" />}
            {(isEap(company) || BBDBAP) && (
              <img className="logo" src={Logo} alt="inkblot logo" />
            )}
            {company && renderBody()}
            <a
              href={`${THERAPY_URL}/redirect/${localStorage.getItem('token')}`}
              target="_blank"
              onClick={this.handleUserClick}
            >
              {isEap(company) && (
                <img
                  className="logo"
                  src={intl.locale === 'en' ? PcEapLogo : PcEapLogoFr}
                  alt="inkblot logo"
                />
              )}
              {BBDBAP && (
                <img
                  className="logo"
                  src={intl.locale === 'en' ? bbdLogo : bbdLogoFr}
                  alt="bbd bap logo"
                />
              )}
              <InkblotTheme>
                <Button
                  people
                  label={<FormattedMessage {...messages.findMyCare} />}
                  style={{ marginTop: '20px', marginBottom: '60px' }}
                />
              </InkblotTheme>
            </a>
          </Card>
        </PortalLayout>
      </div>
    );
  }
}

VirtualCounselling.propTypes = {
  intl: intlShape,
  trackUserClick: PropTypes.func.isRequired,
  fetchUser: PropTypes.func,
  copmany: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    trackUserClick: values => dispatch(trackUserClick(values)),
    fetchUser: callback => dispatch(fetchUser(callback)),
  };
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectApp(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(injectIntl(VirtualCounselling));
